import React, { useState } from 'react';
import Ellipses from './Ellipses'; // Import the loading component
import './ExitBreakdown.css'; // Create this CSS file for custom styling

const ExitBreakdown = ({ data, carryPercentagesAndInvestmentAmount }) => {
    const [exitValue, setExitValue] = useState(""); // State for inputted exit value
    const [exitBreakdown, setExitBreakdown] = useState(null); // State for API response
    const [isLoadingExit, setIsLoadingExit] = useState(false); // Loading state for exit breakdown API

    const handleExitBreakdown = () => {
        setIsLoadingExit(true);
        setExitBreakdown(null);

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit_exitbreakdown' }).then((token) => {

                const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/exit-breakdown'
                    : 'https://' + window.location.hostname + '/api/exit-breakdown';

                const requestData = {
                    recaptcha_token: token,
                    exit_value: parseFloat(exitValue),
                    params: [
                        // Map over investment structures
                        ...data.investment_structures.map(structure => ({
                            name: structure.name,
                            x_vals: structure.x_investor,
                            y_vals: structure.y_investor,
                        })),
                        {
                            name: "Founder",
                            x_vals: data.x_founder,
                            y_vals: data.y_founder,
                        }
                    ],
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                })
                .then(response => response.json())
                .then(data => {
                    setExitBreakdown(data);
                })
                .catch(error => console.error('Error fetching exit breakdown:', error))
                .finally(() => setIsLoadingExit(false));
            });
          });
    };

    return (
        <div className="exit-breakdown-container">
          <h3 className="exit-breakdown">Calculate Exit Breakdown</h3>
          <div className="exit-form">
            <input
              type="number"
              step="0.01"
              placeholder="Enter exit value ($M)"
              value={exitValue}
              onChange={(e) => setExitValue(e.target.value)}
            />
            <button onClick={handleExitBreakdown} disabled={!exitValue || isLoadingExit}>
              {isLoadingExit ? <Ellipses /> : "Calculate"}
            </button>
          </div>

          {exitBreakdown && (
            <div className="exit-results">
              <div className="exit-breakdown-wrapper">
                <table className="exit-breakdown-table">
                  <thead>
                    <tr>
                      <th>Investor</th>
                      <th>Total Exit Value</th>
                      <th>LP Exit Value</th>
                      <th>GP Exit Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(exitBreakdown.breakdowns)
                      .filter(([name]) => name !== "Founder")
                      .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
                      .map(([name, value], index) => (
                        <tr key={index}>
                          <td>{name}</td>
                          <td>${value.toFixed(2)}M</td>
                          <td>
                            ${(
                              (value - (carryPercentagesAndInvestmentAmount[name]?.investment_amount ?? 0)) *
                              (1 - (carryPercentagesAndInvestmentAmount[name]?.performance_fee ?? 0)) +
                              (carryPercentagesAndInvestmentAmount[name]?.investment_amount ?? 0)
                            ).toFixed(2)}M
                          </td>
                          <td>
                            ${(
                              (value - (carryPercentagesAndInvestmentAmount[name]?.investment_amount ?? 0)) *
                              (carryPercentagesAndInvestmentAmount[name]?.performance_fee ?? 0)
                            ).toFixed(2)}M
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="founder-row">
                <p><strong>Founder Exit Value:</strong> ${exitBreakdown.breakdowns.Founder.toFixed(2)}M</p>
              </div>
              <p style={{ margin: 0, fontSize: '12px', marginTop: '10px', color: '#555' }}>
                Note: Exit breakdown assumes a deal-by-deal carry basis with no hurdle rate.
              </p>
            </div>
          )}
        </div>
      );
};

export default ExitBreakdown;
