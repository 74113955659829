import React, { useState } from 'react';
import Ellipses from './Ellipses';
import './BreakevenAnalysis.css'; // Add custom styling

const BreakevenAnalysis = ({ data, submittedData }) => {
    const [selectedSeries, setSelectedSeries] = useState('Series A');
    const [selectedVariable, setSelectedVariable] = useState('VC Ownership');
    const [breakevenValue, setBreakevenValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const seriesOptions = data.investment_structures || {};
    
    const variables = [
        'VC Ownership',
        'Investment Amount',
        'Liquidation Preference Multiple',
        'Annual Management Fee',
        'Carry',
        'Annual Dividend Rate'
    ];

    const handleSeriesChange = (event) => {
        setSelectedSeries(event.target.value);
        setBreakevenValue(null); // Clear breakeven value when series changes
    };

    const handleVariableChange = (event) => {
        setSelectedVariable(event.target.value);
        setBreakevenValue(null); // Clear breakeven value when variable changes
    };

    const calculateBreakeven = () => {
        if (!selectedSeries) {
            alert('Please select a series.');
            return;
        }

        setLoading(true);

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit_breakevenanalysis' }).then((token) => {

                const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                    ? process.env.REACT_APP_API_URL_LOCAL + '/api/breakeven-analysis'
                    : 'https://' + window.location.hostname + '/api/breakeven-analysis';

                const requestData = {
                    breakeven_series: selectedSeries,
                    variable_to_adjust: variableToParameter(selectedVariable),
                    founder_shares: submittedData.founder_shares,
                    subjective_current_value: submittedData.subjective_current_value,
                    params: submittedData.params, // Pass the entire resultData object to the API
                    recaptcha_token: token
                };

                fetch(apiUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestData),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setBreakevenValue(data.variable_value_at_breakeven); // Assume the API returns breakevenValue
                    })
                    .catch((error) => {
                        console.error('Error fetching breakeven analysis:', error);
                        setBreakevenValue('Error fetching breakeven analysis');
                    })
                    .finally(() => setLoading(false));
                });
        });
    };

    const variableToParameter = (variable) => {
        if (variable === 'VC Ownership') {
            return 'vc_shares';
        }

        return variable
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .replace(/\s+/g, '_');
    };

    const calculateTotalSharesOutsideOfSeries = () => {
        let totalShares = submittedData.founder_shares;

        submittedData.params.forEach((investment, index) => {
            if (`Series ${String.fromCharCode(65 + index)}` !== selectedSeries) {
                totalShares += investment.vc_shares;
            }
        });

        return totalShares;
    };

    const formatBreakevenResult = (variable, breakevenValue, diff) => {
        if (breakevenValue === null) {
            return null; // No result yet
        }
    
        if (breakevenValue === 'Error fetching breakeven analysis') {
            return (
                <span> {breakevenValue} </span>
            );
        }

        const formattedBreakevenValue = breakevenValue !== null ? breakevenValue.toFixed(2) : '';

        if (variable === 'VC Ownership') {
            const percentageOwnership = breakevenValue / (breakevenValue + calculateTotalSharesOutsideOfSeries()) * 100;
            return (
                <div>
                    <span>
                        Breakeven {variable}: {formattedBreakevenValue}M Shares ({percentageOwnership.toFixed(2)}% of Cap Table)
                    </span>
                </div>
            );
        } else if (variable === 'Liquidation Preference Multiple') {
            return (
                <div>
                    <span>
                        Breakeven {variable}: {formattedBreakevenValue}
                    </span>
                </div>
            );
        } else if (variable === 'Investment Amount') {
            return (
                <div>
                    <span>
                        Breakeven {variable}: ${formattedBreakevenValue}M
                    </span>
                </div>
            );
        } else {
            const formattedPercentage = breakevenValue !== null ? (breakevenValue * 100).toFixed(2) : '';
            return (
                <div>
                    <span>
                        Breakeven {variable}: {formattedPercentage}%
                    </span>
                </div>
            );
        }
    };


    return (
        <div className="breakeven-analysis">
            <h3>Breakeven Analysis</h3>
            <table className="breakeven-table">
                <tbody>
                    <tr>
                        <td className="label-cell">Series</td>
                        <td className="input-cell">
                            <select
                                value={selectedSeries}
                                onChange={handleSeriesChange}
                                className="dropdown"
                            >
                                {seriesOptions.map((series, index) => (
                                    <option key={index} value={series.name}>
                                        {series.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="label-cell">Breakeven Variable</td>
                        <td className="input-cell">
                            <select
                                value={selectedVariable}
                                onChange={handleVariableChange}
                                className="dropdown"
                            >
                                {variables.map((variable, index) => (
                                    <option key={index} value={variable}>
                                        {variable}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            {selectedVariable === "Annual Dividend Rate" && (
                <p className="breakeven-instructions" style={{ textAlign: 'center' }}>
                    **For Annual Dividend Rate, the breakeven value will be calculated assuming simple dividends**
                </p>
            )}
            <button className="breakeven-calculate-button" onClick={calculateBreakeven}>
                Calculate
            </button>
            <div className="breakeven-result">
                {loading ? (
                    <Ellipses />
                ) : (
                    formatBreakevenResult(selectedVariable, breakevenValue)
                )}
            </div>
            <p className="breakeven-instructions">
                Use this tool to find the breakeven points for various aspects of your deal. Once you select
                a series and variable, this will calculate the value for the selected variable at which the
                deal makes the LP break even.
                <br /> {/* This creates a new line */}
                <span className="centered-bold">
                    Please note that a breakeven value for a variable may not exist
                </span>
            </p>
        </div>
    );
};

export default BreakevenAnalysis;
